<template>
    <b-overlay>
        <b-card>
            <b-button v-if="$checkPermission($permissions.PermissionCreateUser)" variant="primary"
                      @click="addModalActive = true">{{ $t("user.addUser") }}
            </b-button>

            <div>
                <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
                <b-table striped responsive="sm" bordered hover :items="filtered" :fields="fields" :filter="search"
                         :per-page="perPage" :current-page="currentPage">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]"
                                          :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex justify-content-center">
                            <b-button v-if="$checkPermission($permissions.PermissionUpdateUser)" class="mr-1"
                                      @click="editModal(row.item)" variant="warning">
                                <fa icon="edit"/>
                            </b-button>
                            <b-button v-if="$checkPermission($permissions.PermissionDeleteUser)" @click="deleteUser(row.item)"
                                      variant="danger">
                                <fa icon="trash"/>
                            </b-button>
                        </div>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                             @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal title="Dodaj uporabnika" v-model="addModalActive" centered hide-footer>
            <template #default>
                <validation-observer ref="loginValidation">
                    <b-form class="auth-login-form mt-2" @submit.prevent>
                        <validation-provider name="Name" rules="required" #default="{ errors }">
                            <b-form-group label="Ime" rules="required" label-for="user-name" invalid-feedback="Ime je obvezno"
                                          :state="errors[0] ? false:null">
                                <b-form-input id="user-name" v-model="userObject.name" name="user-name"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Surname" rules="required" #default="{ errors }">
                            <b-form-group label="Priimek" rules="required" label-for="user-surname"
                                          invalid-feedback="Priimek je obvezen" :state="errors[0] ? false:null">
                                <b-form-input id="user-surname" v-model="userObject.surname" name="user-surname"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Email" rules="required|email" #default="{ errors }">
                            <b-form-group label="Email" rules="required|email" label-for="user-email"
                                          invalid-feedback="Email je v nepravilni obliki" :state="errors[0] ? false:null">
                                <b-form-input id="user-email" v-model="userObject.email" name="user-email"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Role" rules="required" #default="{ errors }">
                            <b-form-group label="Vrsta uporabnika" invalid-feedback="Izbira je obvezna"
                                          :state="errors[0] ? false:null">
                                <b-form-radio-group v-model="userObject.role" :options="roles" value-field="id" text-field="title"
                                                    :state="errors[0] ? false:null">
                                </b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Password" rules="required" #default="{ errors }">
                            <b-form-group label="Geslo" rules="required" label-for="user-password" invalid-feedback="Geslo je obvezno"
                                          :state="errors[0] ? false:null">
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                    <b-form-input id="user-password" v-model="userObject.password" :type="passwordFieldType"
                                                  name="user-password" :state="errors[0] ? false:null"/>
                                    <b-input-group-append is-text>
                                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                        <b-button type="submit" variant="primary" @click="validationForm">
                            Dodaj
                        </b-button>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>

        <b-modal title="Uredi uporabnika" v-model="editModalActive" centered hide-footer>
            <template #default>
                <validation-observer ref="loginValidation">
                    <b-form class="auth-login-form mt-2" @submit.prevent>
                        <validation-provider name="Name" rules="required" #default="{ errors }">
                            <b-form-group label="Ime" rules="required" label-for="edit-name" invalid-feedback="Ime je obvezno"
                                          :state="errors[0] ? false:null">
                                <b-form-input id="edit-name" v-model="selectedUser.name" name="edit-name"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Surname" rules="required" #default="{ errors }">
                            <b-form-group label="Priimek" rules="required" label-for="edit-surname"
                                          invalid-feedback="Priimek je obvezen" :state="errors[0] ? false:null">
                                <b-form-input id="edit-surname" v-model="selectedUser.surname" name="edit-surname"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Email" rules="required|email" #default="{ errors }">
                            <b-form-group label="Email" rules="required|email" label-for="edit-email"
                                          invalid-feedback="Email je v nepravilni obliki" :state="errors[0] ? false:null">
                                <b-form-input id="edit-email" v-model="selectedUser.email" name="edit-email"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="Role" rules="required" #default="{ errors }">
                            <b-form-group label="Vrsta uporabnika" invalid-feedback="Izbira je obvezna"
                                          :state="errors[0] ? false:null">
                                <b-form-radio-group v-model="selectedUser.role" :options="roles" value-field="id" text-field="title"
                                                    :state="errors[0] ? false:null">
                                </b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                        <b-form-group label="Geslo" label-for="edit-password">
                            <b-input-group class="input-group-merge">
                                <b-form-input id="edit-password" v-model="selectedUser.password" :type="passwordFieldType"
                                              name="edit-password"/>
                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                        <b-button type="submit" variant="primary" @click="validationFormEdit">
                            Uredi
                        </b-button>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {required, email} from '@validations'
    import {togglePasswordVisibility} from '@core/mixins/ui/forms'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                addModalActive: false,
                editModalActive: false,
                userObject: {id: '', name: '', surname: '', email: '', role: '', password: ''},
                roles: [],
                users: [],
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                totalRows: 1,
                selectedUser: {},
                fields: [
                    {key: 'name', label: 'Ime', sortable: true, class: 'text-center'},
                    {key: 'surname', label: 'Priimek', sortable: true, class: 'text-center'},
                    {key: 'email', label: 'Email', sortable: true, class: 'text-center'},
                    {key: 'actions', label: '', class: 'text-center'}
                ],
                filters: {Id: '', Ime: '', Priimek: '', Email: ''},
                required,
                email
            }
        },
        methods: {
            getUsers() {
                const thisIns = this
                this.$http.get('/api/v1/management/user/')
                    .then(function(response) {
                        thisIns.users = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            getRoles() {
                const thisIns = this
                this.$http.get('/api/v1/management/role')
                    .then(function(response) {
                        thisIns.roles = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            validationForm() {
                this.$refs.loginValidation.validate().then((response) => {
                    if (response) this.addUser()
                })
            },
            validationFormEdit() {
                this.$refs.loginValidation.validate().then((response) => {
                    if (response) this.updateUser()
                })
            },
            addUser() {
                const thisIns = this
                this.$http.put('/api/v1/management/user', thisIns.userObject)
                    .then(function() {
                        thisIns.addModalActive = false
                        thisIns.userObject = {id: '', name: '', surname: '', email: '', role: '', password: ''}
                        thisIns.$printSuccess('Uporabnik je dodan!')
                        thisIns.getUsers()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            updateUser() {
                const thisIns = this
                const userId = thisIns.selectedUser.id
                thisIns.selectedUser.id = ''
                this.$http.patch(`/api/v1/management/user/${userId}`, thisIns.selectedUser)
                    .then(function() {
                        thisIns.editModalActive = false
                        thisIns.selectedUser = {}
                        thisIns.$printSuccess('Uporabnik je posodobljen!')
                        thisIns.getUsers()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            editModal(item) {
                this.selectedUser = item
                this.editModalActive = true
            },
            deleteUser(userId) {
                const thisIns = this
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati uporabnika.', {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'DA',
                    cancelTitle: 'NE',
                    footerClass: 'p-2',
                    centered: true
                }).then(value => {
                    if (value && value !== null) {
                        this.$http.delete(`/api/v1/management/user/${userId}`)
                            .then(function() {
                                thisIns.$printSuccess('Uporabnik je izbrisan!')
                                thisIns.getUsers()
                            }).catch(function(error) {
                                thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                            })
                    }
                })
            },
            setPerPage(item) {
                this.perPage = item
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            filtered() {
                const filtered = this.users.filter(user => {
                    return Object.keys(this.filters).every(key => String(user[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
                })
                return filtered.length > 0 ? filtered : [{Id: '', Ime: '', Priimek: '', Email: ''}]
            }
        },
        created() {
            this.getUsers()
            this.getRoles()
        },
        mounted() {
            this.totalRows = this.users.length
        }
    }
</script>

<style>

</style>
